body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#nprogress {
  .bar {
    background: $primary;
  }
  .peg {
    box-shadow: 0 0 10px $primary, 0 0 5px $primary;
  }

  .spinner-icon {
    display: none
  }
}

.flatpickr-monthSelect-months {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 80px;

  .flatpickr-monthSelect-month {
    cursor: pointer;
    max-height: 16px;
    padding: 2px 4px;
  }
}
