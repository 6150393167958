// Your variable overrides
$primary: #6138fe;

$theme-colors: (
  "primary": $primary,
  "secondary": #f087b3,
  "danger": #ca1818,
  "success": #0c9151,
  "warning": #f0bb00,
  "info": #0068a5,
);

$gray-100: #fefefe;
$gray-200: #d4d3d8;
$gray-300: #cacbd2;
$gray-400: #928e9e;
$gray-500: #76777c;
$gray-600: #65666b;
$gray-700: #514c5d;
$gray-800: #35313f;
$gray-900: #222027;

// Bootstrap and its default variables
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

@import "./index.scss";
